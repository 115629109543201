import Head from 'next/head';
import { useRouter } from 'next/router';

import mapConfig from 'config/map';
import config from 'config';

interface HeadProps {
  title: string;
  description?: string;
  canonicalLink?: string;
  apiUrl?: string;
  metadata?: {
    name?: string;
    property?: string;
    content: string;
  }[];
  scripts?: {
    content: string;
    type: string;
  }[];
}

function HeadComponent({
  title,
  description,
  canonicalLink,
  metadata,
  scripts,
}: HeadProps) {
  const router = useRouter();
  return (
    <Head>
      <title>{title} | Haul</title>
      <meta name="description" content={description} />
      <meta name="keyword" content="haul,drivers,fleets,ryder" />
      <meta
        property="og:url"
        content={`https://haulwith.us${router.pathname}`}
      />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="/images/head_logo.jpeg" />

      {metadata?.map((item) => (
        <meta {...item} />
      ))}

      {canonicalLink && (
        <link rel="canonical" href={`https://haulwith.us${canonicalLink}`} />
      )}

      {scripts?.map((s, i) => (
        <script
          key={`script-item-${i}`}
          type={s.type}
          dangerouslySetInnerHTML={{ __html: s.content }}
        />
      ))}
      <link
        rel="stylesheet"
        href="https://blog.haulwith.us/wp-includes/css/dist/block-library/style.min.css?ver=5.7"
      />
      <script
        src={`https://www.googleoptimize.com/optimize.js?id=${config.googleOptimize}`}
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${config.segmentId}";;analytics.SNIPPET_VERSION="4.15.3";
          analytics.load("${config.segmentId}");
          analytics.page();
          }}();`,
        }}
      />

      {/* Google Tag Manager */}
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-N6TWKHC');`,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${config.googleOptimizeTagManager}');`,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
                 function gtag() {
                  window.dataLayer.push(arguments);
                 }
                 gtag('js', new Date());
                 gtag('config', '${config.googleOptimizeTagManager}');`,
        }}
      ></script>
      {/* End Google Tag Manager */}

      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: ' window.googleCallback = function() {}',
        }}
      />

      <script async type="text/javascript" src={mapConfig.placesUrl} />
    </Head>
  );
}

HeadComponent.defaultProps = {
  description: undefined,
  canonicalLink: undefined,
};

export default HeadComponent;
