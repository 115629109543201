export enum EGtmEvents {
  DriverLead = 'DriverLead',
  FleetLead = 'FleetLead',
  DriverApply = 'DriverApply',
  SignUpClick = 'SignUpClick',
  PostJobsBtnClick = 'PostJobsBtnClick',
}

export const gtmPushEvent = (event: EGtmEvents): void => {
  try {
    // @ts-ignore
    window.dataLayer?.push({ event });
  } catch (error) {
    console.error('gtmPushEvent', event, error);
  }
};
