import clsx from 'clsx';
import styles from './styles.module.scss';

type InputProps = {
  title?: string;
  subtitle?: string;
  text?: string;
  className?: string;
};

export const SignUpSuccessful = ({
  title,
  subtitle,
  text,
  className,
}: InputProps) => {
  return (
    <div className={clsx(styles.root, className)}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      {text && <div className={styles.text}>{text}</div>}
    </div>
  );
};
