import React from 'react';
import Alert from 'react-bootstrap/Alert';

type InputProps = {
  className?: string;
  message: string;
  onClose: () => void;
};

export const AlertError = ({ message, className, onClose }: InputProps) => {
  return (
    <Alert variant="danger" onClose={onClose} className={className} dismissible>
      {message}
    </Alert>
  );
};
