import { useState } from 'react';
import {
  FormikConfig,
  FormikTouched,
  useFormik as FormikUseFormik,
} from 'formik';
import { IUseFormik } from './interfaces';

function useFormik<Values>(
  formikConfig: FormikConfig<Values>,
  formStepsFields: Array<string[]> = []
): IUseFormik<Values> {
  const [currentFormStep, setCurrentFormSteps] = useState(1);
  const totalFormSteps = formStepsFields.length;

  const formik = FormikUseFormik({ ...formikConfig, validateOnMount: true });

  return {
    ...formik,
    currentFormStep,
    totalFormSteps,
    goStep(step = -1) {
      setCurrentFormSteps((prev) => {
        if (step < 0 && prev + step >= 1) {
          return prev + step;
        } else if (step < prev && step >= 1) {
          return step;
        }

        return prev;
      });
    },
    handleSubmit(...args: Parameters<typeof formik.handleSubmit>) {
      args[0]?.preventDefault();

      if (totalFormSteps > 0 && currentFormStep < totalFormSteps) {
        const invalidFields = Object.keys(formik.errors).filter((item) =>
          formStepsFields[currentFormStep - 1].includes(item)
        );

        if (invalidFields.length <= 0) {
          setCurrentFormSteps((prev) => prev + 1);
        } else {
          formik.setTouched(
            invalidFields.reduce((acc, item) => {
              acc[item] = true;

              return acc;
            }, {} as Record<string, boolean>) as FormikTouched<Values>
          );
        }
      } else if (currentFormStep >= totalFormSteps) {
        formik.handleSubmit(...args);
      }
    },
    getFieldErrorProp(field: keyof Values) {
      return {
        error: formik.touched[field]
          ? (formik.errors[field] as string)
          : undefined,
      };
    },
    getFieldProps<V extends keyof Values>(field: V) {
      return {
        name: field,
        value: formik.values[field] as Values[V],
        onChange: (value: unknown) => {
          if (Object.keys(formik.touched).includes(field as string)) {
            formik.setFieldTouched(field as string, false);
          }

          formik.setFieldValue(field as string, value);
        },
        // onBlur: formik.handleBlur,
      };
    },
  };
}

export default useFormik;
