const API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
const googleMapUrl = `https://maps.googleapis.com/maps/api/staticmap?key=${API_KEY}`;

const mapConfig = {
  getStaticMapUrl: (
    center: string,
    size: string,
    maptype: string,
    zoom?: number
  ) => {
    return `${googleMapUrl}&center=${center}&size=${size}&maptype=${maptype}&zoom=${
      zoom || 13
    }`;
  },
  placesUrl: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&language=en&callback=googleCallback`,
};

export default mapConfig;
