import { useTranslation } from 'react-i18next';
import Image from 'next/image';

export default function Footer() {
  const [t] = useTranslation('common');
  const tFooter = (path: string) => t(`footer.${path}`);
  return (
    <footer>
      <div className="container container-footer">
        <div className="row no-gutters">
          <div className="col-12 col-lg-4 column">
            <Image
              src="/images/v2/logo.svg"
              alt="haul"
              width={252}
              height={71}
              className="footer-logo-image"
            />
          </div>

          <div className="col-6 col-lg-2 column pt-2">
            <h4>{tFooter('company')}</h4>
            <a
              href="https://wellfound.com/company/haulwithus/jobs"
              className="footer-link"
            >
              <span>{tFooter('career')}</span>
            </a>
            <a href="/privacypolicy" className="footer-link">
              <span>{tFooter('privacyPolicy')}</span>
            </a>
            <a href="/termsofservice" className="footer-link">
              <span>{tFooter('termsConditions')}</span>
            </a>
          </div>

          <div className="col-6 col-lg-2 column pt-2">
            <h4>{tFooter('follow')}</h4>
            <a
              href="https://www.instagram.com/haulwithus/"
              className="footer-link"
              target="_blank"
            >
              <Image
                src="/images/social/instagram.svg"
                alt="haul"
                width={18}
                height={18}
              />
              <span className="ml-2 d-inline-block">Instagram</span>
            </a>
            <a
              href="https://www.facebook.com/haulwith"
              className="footer-link"
              target="_blank"
            >
              <Image
                src="/images/social/facebook.svg"
                alt="haul"
                width={18}
                height={18}
              />
              <span className="ml-2 d-inline-block">Facebook</span>
            </a>

            <a
              className="footer-link"
              href="https://twitter.com/haulwithus"
              target="_blank"
            >
              <Image
                src="/images/social/twitter.svg"
                alt="haul"
                width={18}
                height={18}
              />
              <span className="ml-2 d-inline-block">Twitter</span>
            </a>

            <a
              href="https://www.tiktok.com/@haulwith.us"
              className="footer-link"
              target="_blank"
            >
              <Image
                src="/images/social/tiktok.svg"
                alt="haul"
                width={20}
                height={20}
              />
              <span className="ml-2 d-inline-block">TikTok</span>
            </a>
          </div>

          <div className="col-6 col-lg-2 column pt-2">
            <a
              href="https://apps.apple.com/us/app/haul-driver/id1524004807"
              target="_blank"
            >
              <Image
                src="/images/app-stores/app-store-white.svg"
                alt="App Store"
                width={149}
                height={52}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.haul"
              target="_blank"
            >
              <Image
                src="/images/app-stores/google-store-white.svg"
                alt="Google Play Store"
                width={162}
                height={52}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="copyrights">
        © {new Date().getFullYear()} {tFooter('truckingJobs')}
      </div>
    </footer>
  );
}
