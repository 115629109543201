import Header from './Header';
import Footer from './Footer';
import cl from 'classnames';
import styles from './styles.module.scss';

interface MainLayoutProps {
  blockScroll?: boolean;
  children: JSX.Element;
}

export default function MainLayout({ children, blockScroll }: MainLayoutProps) {
  return (
    <div
      className={cl(styles.landingPageContainer, {
        [styles.blockScroll]: blockScroll,
      })}
    >
      <div>
        <Header />
        <div>{children}</div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
