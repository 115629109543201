import React from 'react';
import clsx from 'clsx';
import { IButtonProps } from '../index';
import styles from './index.module.scss';

export const DarkButton = ({ className, children, ...props }: IButtonProps) => {
  return (
    <button className={clsx(styles.root, className)} {...props}>
      {children}
    </button>
  );
};
