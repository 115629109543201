import clsx from 'clsx';
import { ReactNode, useRef } from 'react';

import styles from './styles.module.scss';

type InputProps = {
  type?: 'text' | 'password' | 'number' | 'textarea';
  value: string | number;
  onChange: (newValue: InputProps['value']) => void;
  className?: string;
  classNamePrefix?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  disabled?: boolean;
  autofocus?: boolean;
  [K: string]: unknown;
};

function FormInput({
  type = 'text',
  value,
  onChange,
  className,
  classNamePrefix,
  prefix,
  suffix,
  disabled,
  autofocus,
  ...props
}: InputProps) {
  const style: Record<string, number> = {};

  const prefixRef = useRef<HTMLDivElement | null>(null);
  const suffixRef = useRef<HTMLDivElement | null>(null);

  if (prefixRef.current) {
    style.paddingLeft = prefixRef.current.getBoundingClientRect().width + 10;
  }

  if (suffixRef.current) {
    style.paddingRight = suffixRef.current.getBoundingClientRect().width + 10;
  }

  return (
    <div className={clsx(styles.root, disabled && styles.disabled)}>
      {prefix && (
        <div className={clsx(styles.prefix, classNamePrefix)} ref={prefixRef}>
          {prefix}
        </div>
      )}
      {type === 'textarea' ? (
        <textarea
          {...props}
          style={style}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          className={clsx(styles.input, className)}
          disabled={disabled}
        />
      ) : (
        <input
          {...props}
          style={style}
          type={type}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          className={clsx(styles.input, className)}
          disabled={disabled}
          autoFocus={autofocus}
        />
      )}
      {suffix && (
        <div className={styles.suffix} ref={suffixRef}>
          {suffix}
        </div>
      )}
    </div>
  );
}

export default FormInput;
