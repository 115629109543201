import clsx from 'clsx';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type IProps = {
  label?: string;
  required?: boolean;
  error?: string;
  children: ReactNode;
  className?: string;
};

function FormInputRow({ label, required, error, children, className }: IProps) {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles.root, !!error && styles.error, className)}>
      {label && (
        <div className={clsx(styles.label, required && styles.required)}>
          {label}
        </div>
      )}
      <div className={styles.body}>{children}</div>
      {error && <div className={styles.errorContainer}>{t(error)}</div>}
    </div>
  );
}

export default FormInputRow;
